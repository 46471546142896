import axios from "axios";

export default axios.create({
    baseURL: 'https://api.myesadoctor.com/wp-json/wp/v2',
    header: {

    },
})


// baseURL: 'https://api.myesadoctor.com//wp-json/wp/v2',
//https://api.myesadoctor.com/wp-json/wp/v2/menu