import React, { Component } from 'react';
import {Helmet} from "react-helmet";
export default class MyMetaTags extends Component {
    render() {
        if(this.props.myloading){
        return (
            <>
            <Helmet>
                <meta name="google-site-verification" content={this.props.mytags.googlesiteverificationcontent} />
                <title>{this.props.mytags.metatitle}</title>
                <meta name="description" content={this.props.mytags.metadescription} />
                <link rel="canonical" href={this.props.mytags.canonicaltag} />
                <meta name="robots" content={this.props.mytags.robotscontent} />
                <meta property="og:locale" content={this.props.mytags.metapropertyoglocalecontent} />
                <meta property="og:type" content={this.props.mytags.metapropertyogtypecontent} />
                <meta property="og:title" content={this.props.mytags.metapropertyogtitlecontent} />
                <meta property="og:description" content={this.props.mytags.metapropertyogdiscriptioncontent} />
                <meta property="og:url" content={this.props.mytags.metapropertyogurlcontent} />
                <meta property="og:site_name" content={this.props.mytags.metapropertyogsitenamecontent} />
                <meta name="twitter:card" content={this.props.mytags.metanametwittercardcontent} />
                <meta name="twitter:title" content={this.props.mytags.metanametwittertitlecontent} />
                <meta name="twitter:description" content={this.props.mytags.metapropertyogdiscriptioncontent} />
                <meta name="twitter:site" content={this.props.mytags.metanametwittersitecontent} />
                <meta name="twitter:creator" content={this.props.mytags.metanametwittercreatorcontent} />
                <meta name="generator" content={this.props.mytags.metanamegeneratorcontent} />
                <meta name="msapplication-TileImage" content={this.props.mytags.metanamemsapplicationtileimagecontent ? this.props.mytags.metanamemsapplicationtileimagecontent : 'https://api.myesadoctor.com/wp-content/uploads/2022/05/small-logo.png'} />   
            </Helmet>
            </>
        )
        }
         return (
            <> 
            </>
        )
    }
}
